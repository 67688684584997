@use "sass:map";
@import "../../../../../../assets/styles/utils.scss";
@import "../../../../../../assets/styles/variables/prefixes";

@mixin theme($options: ()) {
  wb-ui-tab-selector-v2 {
    @include styles(map.get($options, default));

    &.indicator {
      @include styles(map.get($options, indicator));
    }

    &.background {
      @include styles(map.get($options, backgrounded));
    }
  }
}

@mixin styles($list) {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == tabs) {
        @include buildSelectorStyles(".tabs", $p1);
      }@else if($n1 == after) {
        &::after {
          @each $n2, $p2 in $p1 {
            #{$n2}: $p2;
          }
        }
      }@else if($n1 == tab) {
        @include buildTab($p1);
      }
    } @else if $p1 and $p1 != (){
      #{$n1}: $p1;
    }
  }
}

@mixin buildTab($list, $name: '.tab') {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if ($n1 == default) {
        @include buildTab($p1, $name)
      } @else if($n1 == active) {
        @include buildTab($p1, $name + '.active')
      }@else if($n1 == disabled) {
        @include buildTab($p1, $name + '.disabled')
      } @else if($n1 == hover) {
        @include buildTab($p1, $name + ':hover:not(.disabled)')
      } @else if($n1 == first-child) {
        @include buildTabContent($p1, $name + ':first-child')
      } @else if($n1 == last-child) {
        @include buildTabContent($p1, $name + ':last-child')
      } @else if($n1 == content) {
        #{$name} {
          @include buildTabContent($p1)
        }
      }
    } @else if $p1 and $p1 != () {
      #{$name} {
        #{$n1}: $p1;
      }
    }
  }
}

@mixin buildTabContent($list, $name: '.tab-content') {
  @each $n1, $p1 in $list {
    @if type-of($p1) == "map" {
      @if($n1 == after) {
        @include buildTabContent($p1, $name + '::after')
      }@else if($n1 == title) {
        @include buildTabContent($p1, $name + ' .tab-title')
      }
    } @else if $p1 and $p1 != () {
      #{$name} {
        #{$n1}: $p1;
      }
    }
  }
}